import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import i18nFeedback from './locales/en-US/feedback.json';

i18n
  // TODO: https://react.i18next.com/latest/using-with-hooks#configure-i-18-next
  // TODO: https://github.com/i18next/i18next-http-backend
  .use(initReactI18next)
  .init({
    resources: {
      'en-US': {
        feedback: i18nFeedback,
      },
    },
    lng: 'en-US',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  })
  .then(() => {});

export default i18n;
