import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './i18n';

const FeedbackTrigger = React.lazy(() => import('./feedback/Trigger'));

ReactDOM.render(
  <Suspense fallback={<div />}>
    <FeedbackTrigger />
  </Suspense>,
  document.getElementById("feedbackTrigger")
);
